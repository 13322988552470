import React from "react";
import productsPageStyles from '../../styles/productsPage.module.scss';
import Layout from "../../components/layout";
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import data from '../../data/products';

import { Helmet } from 'react-helmet';
export default function (props) {
    // console.log(props);

    const { pressurizer, airFilters } = useStaticQuery(
        graphql`query productsImages {
            pressurizer: file(relativePath: {eq: "pressurizer.png"}) {
                childImageSharp {
                    fluid(maxHeight: 600) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                    fixed(width: 200) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
            airFilters: file(relativePath: {eq: "products-air-filters.png"}) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                    fixed(width: 400) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
        }`
    );
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Products | Clean Air Filter</title>
        </Helmet>
        <article className={productsPageStyles.wrapper}>
          <section>
            <h1>
              <span className="head">{data.content.section1.h1.head}</span>{" "}
              <span className="tail">{data.content.section1.h1.tail}</span>
            </h1>
            <p>{data.content.section1.p}</p>
          </section>
          <section>
            <article className="w-60">
              <div className={productsPageStyles.product}>
                <div className={productsPageStyles.desc}>
                  <h2>{data.content.section2.h2}</h2>
                  <p>{data.content.section2.p1}</p>
                  <Link
                    className="primary red"
                    to="/products/cabin-air-filters"
                  >
                    {data.content.section2.a}
                  </Link>
                </div>
                <div className={productsPageStyles.airFiltersImg}>
                  <Img
                    fluid={airFilters.childImageSharp.fluid}
                    style={{ maxWidth: 400 }}
                  />
                </div>
              </div>
            </article>
          </section>
          <hr></hr>
          <section>
            <article className="w-60">
              <div className={productsPageStyles.product}>
                <div className={productsPageStyles.desc}>
                  <h2>{data.content.section3.h2}</h2>
                  <p>{data.content.section3.p1}</p>
                  <Link
                    className="primary red"
                    to="/products/filtration-protection-systems"
                  >
                    {data.content.section3.a}
                  </Link>
                </div>

                <div className={productsPageStyles.pressurizerImg}>
                  <Img
                    fluid={pressurizer.childImageSharp.fluid}
                    style={{ maxWidth: 450 }}
                  />
                </div>
              </div>
            </article>
          </section>
          <hr></hr>
          <section>
            <article className="w-60">
              <div className={productsPageStyles.product}>
                <div
                  className={productsPageStyles.desc}
                  style={{ maxWidth: 800 }}
                >
                  <h2>{data.content.section4.h2}</h2>
                  <p>{data.content.section4.p1}</p>
                  <Link
                    className="primary red"
                    to="/products/air-testing-services"
                  >
                    {data.content.section4.a}
                  </Link>
                </div>

                {/* <div className={productsPageStyles.pressurizerImg}>
                  <Img
                    fluid={pressurizer.childImageSharp.fluid}
                    style={{ maxWidth: 450 }}
                  />
                </div> */}
              </div>
            </article>
          </section>
        </article>
      </Layout>
    )
}
